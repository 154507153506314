<template>
  <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle"
       style="display: block; background: #1c1d1c33">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">{{ modalTitle }}</h1>
              <button
                  @click="toggleModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0">

        <div class="pb-2">
          <!-- form -->
          <vForm @submit="submit" :validation-schema="schema">
            <div class="row gy-2 px-2">

              <div class="col-md-6 col-6">
                <label class="form-label">Type</label>
                <v-select
                    v-model="formData.location_type"
                    label="name"
                    :options="typeArr"
                    :reduce="name => name.id"
                />
              </div>

              <div v-if="showParent" class="col-md-6 col-6">
                <label class="form-label">Campaign Group</label>
                <v-select
                    v-model="formData.head_group_id"
                    label="name"
                    :options="group"
                    :reduce="name => name.id"
                />
              </div>

              <div class="col-12">
                <label class="form-label" for="chartName">Name</label>
                <div class="input-group">
                  <vField
                      as="textarea"
                      name="name"
                      v-model="formData.name"
                      class="form-control add-credit-card-mask"
                      placeholder="Name"
                  />
                  <ErrorMessage name="name" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <!-- <vField
                      as="textarea"
                      v-model="formData.full_desc"
                      name="full_desc"
                      id="Full"
                      class="form-control add-credit-card-mask"
                      placeholder="Full Description"
                  /> -->
 
              <div class="col-6">
                <label class="form-label" for="startDate">Start Date</label>
                <div class="input-group">
                  <vField
                      type="date"
                      name="start_date"
                      v-model="formData.start_date"
                      class="form-control add-credit-card-mask"
                      placeholder="Start Date"
                  />
                  <ErrorMessage name="start_date" class="invalid-feedback d-block qcont"/>
                </div>
              </div>
 
              <div class="col-6">
                <label class="form-label" for="startDate">End Date</label>
                <div class="input-group">
                  <vField
                      type="date"
                      name="end_date"
                      v-model="formData.end_date"
                      class="form-control add-credit-card-mask"
                      placeholder="End Date"
                  />
                  <ErrorMessage name="end_date" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <div class="col-6">
                <label class="form-label" for="expected_revenue">Expected Revenue</label>
                <div class="input-group">
                  <vField
                      type="number"
                      name="expected_revenue"
                      v-model="formData.expected_revenue"
                      class="form-control add-credit-card-mask"
                      placeholder="Expected Revenue"
                  />
                  <ErrorMessage name="expected_revenue" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <div class="col-6">
                <label class="form-label" for="budget_cost">Budget Cost</label>
                <div class="input-group">
                  <vField
                      type="number"
                      name="budget_cost"
                      v-model="formData.budget_cost"
                      class="form-control add-credit-card-mask"
                      placeholder="Budget Cost"
                  />
                  <ErrorMessage name="budget_cost" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <div class="col-6">
                <label class="form-label" for="actual_cost">Actual Cost</label>
                <div class="input-group">
                  <vField
                      type="number"
                      name="actual_cost"
                      v-model="formData.actual_cost"
                      class="form-control add-credit-card-mask"
                      placeholder="Actual Cost"
                  />
                  <ErrorMessage name="actual_cost" class="invalid-feedback d-block qcont"/>
                </div>
              </div>

              <div class="col-6">
                  <label class="form-label" for="roi">ROI</label>
                  <div class="input-group">
                    <vField
                        type="text"
                        name="roi"
                        v-model="formData.roi"
                        class="form-control add-credit-card-mask"
                        placeholder="ROI"
                    />
                    <ErrorMessage name="roi" class="invalid-feedback d-block qcont"/>
                  </div>
              </div>

                <div v-if="showParent" class="col-md-6 col-6">
                  <label class="form-label">Campaign Owner</label>
                  <v-select
                      v-model="formData.campaign_owner_id"
                      label="name"
                      :options="[]"
                      :reduce="name => name.id"
                  />
                </div>

              <div class="col-6">
                <label class="form-label">Tags</label>
                <v-select taggable multiple push-tags />
                <!-- <v-select
                    v-model="formData.tags"
                    taggable
                    multiple
                    no-drop
                    placeholder="Please add tags"
                /> -->

              </div>

              <div class="col-6">
                <label class="form-label" for="Short_desc">Short Description</label>
                <vField
                    as="textarea"
                    v-model="formData.short_desc"
                    name="short_desc"
                    id="Short_desc"
                    class="form-control add-credit-card-mask"
                    placeholder="Short Description"
                />
                <ErrorMessage name="short_desc" class="invalid-feedback d-block qcont"/>
              </div>

              <div class="col-6">
                <label class="form-label" for="Full">Full Description</label>
                <vField
                    as="textarea"
                    v-model="formData.full_desc"
                    name="full_desc"
                    id="Full"
                    class="form-control add-credit-card-mask"
                    placeholder="Full Description"
                />
                <ErrorMessage name="full_desc" class="invalid-feedback d-block qcont"/>
              </div>
              


            </div>

            
            

            <div class="col-12 text-center">
              <hr class="mt-2">
              <button :disabled="loading" type="submit"
                      class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                Submit
              </button>
              <button @click.prevent="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                Cancel
              </button>
            </div>
          </vForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import objToArray from '@/services/utils/object-to-array'

export default {
  name: 'NewLocation',

  data: () => ({
    isOpen : false,
    loading: false,
    schema : {
      name: 'required|min:3|max:100',
      code: 'required|min:3|max:100',
    }
  }),

  computed: {
    statusArr() {
      return Object.keys(this.status)
    },
    typeArr() {
      return objToArray(this.type)
    }
  },

  methods: {
    submit() {
      this.$emit(this.submitEvent)
    },
    toggleModal() {
      this.isOpen = !this.isOpen
      if (!this.isOpen) {
        this.$emit("onCloseLocationModal")
      }
    }
  },

  props: {
    formData   : Object,
    showParent : {
      type   : Boolean,
      default: false
    },
    type       : Object,
    group      : Array,
    status     : Object,
    modalTitle : {
      type   : String,
      default: 'Add Location'
    },
    submitEvent: {
      default: 'onSubmitLocation'
    }
  }
}
</script>
