<template>
    <div class="card p-2" style="min-height: calc(100vh - 185px);">
        <div class="d-flex flex-wrap justify-content-between align-item-center">
            <h3 class="_campaign_title">Campaign List</h3>

            <div class="input-group input-group-merge ms-1" style="width: 40%;">
                <span class="input-group-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search text-muted">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                </span>
              <input type="text" class="form-control"
                id="chat-search" v-model.trim="searchValue"
                @input="filterDeals"
                placeholder="Quick Search.." aria-label="Search..."
                aria-describedby="chat-search"/>

                <span class="input-group-text">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search text-muted">
                            <path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"></path>
                    </svg>
                </span>
            </div>

            <div class="gx-1">
                
                <button style="min-width: 64px;" @click="openGroupAddModal"
                    class="btn btn-primary waves-effect waves-float waves-light me-1">New Group
                </button>
                <button style="min-width: 64px;" @click="openLocationAddModal"
                    class="btn btn-primary waves-effect waves-float waves-light">New Campaign
                </button>
            </div>
        </div>
        <div class="mt-2"></div>
        <Tab @onClickTab="onClickTab" :tabs="homeContent.business_location_type" routeName="business-location" />
        <hr>
        <TreeBrowser :style="{ opacity: chartLoading ? .5 : 1 }" v-for="(item, i) in locationList" :key="i" :node="item" />
        <p v-if="!locationList.length" class="text-center mt-5">No data</p>

        <GroupAddModal modalTitle="Add Group" ref="group" :type="homeContent.business_location_type"
            :formData="groupAddData" :status="homeContent.status" :group="businessLocationGroup"
            @onSubmitGroup="handleGroupSubmit" />
        <GroupAddModal submitEvent="onUpdateGroup" :showParent="true" modalTitle="Edit Group" ref="groupedit"
            :type="homeContent.business_location_type" :formData="groupEditData" :status="homeContent.status"
            :group="businessLocationGroup" @onUpdateGroup="handleGroupUpdate" />

        <LocationAddModal 
            :showParent="true" modalTitle="Create Campaign" ref="location"
            :type="homeContent.business_location_type" 
            :formData="locationAddData" :status="homeContent.status"
            :group="businessLocationGroup" @onSubmitLocation="handleLocationSubmit" 
        />

        <LocationAddModal submitEvent="onUpdateLocation" :showParent="true" modalTitle="Edit Location" ref="locationedit"
            :type="homeContent.business_location_type" :formData="locationEditData" :status="homeContent.status"
            :group="businessLocationGroup" @onUpdateLocation="handleLocationUpdate" />

        <Loader v-if="loading" />
    </div> 
</template>

<script>
import handleInventory from '@/services/modules/inventory'
import { inject } from 'vue'
import { mapMutations } from 'vuex'

import TreeBrowser from '@/components/molecule/company/campaign/LocationTree'
import Tab from '@/components/atom/Tab'
import TitleButton from '@/components/atom/TitleButton'
import Loader from '@/components/atom/LoaderComponent'
import GroupAddModal from '@/components/molecule/company/campaign/GroupAddModal'
import LocationAddModal from '@/components/molecule/company/campaign/LocationAddModal'

export default {
    name: 'BusinessLocationList',

    components: {
        TitleButton,
        TreeBrowser,
        Tab,
        Loader,
        GroupAddModal,
        LocationAddModal
    },

    data: () => ({
        loading: false,
        businessLocationGroup: [],
        locationList: [],
        accountHeads: [],
        isGroup: false,
        parentGroup: '',
        homeContent: {},
        groups: [],
        groupAddData: {
            name: '',
            location_type: null,
            status: 'active',
            company_id: '',
            head_group_id: null
        },
        groupEditData: {},
        locationAddData: {
            company_id: '',
            head_group_id: null,
            campaign_type: null,
            name: '',
            start_date: '',
            end_date: '',
            expected_revenue: '',
            budget_cost: '',
            actual_cost: '',
            actual_cost: '',
            roi: '',
            short_desc: '',
            full_desc: '',
            campaign_owner_id: '',
            tags: [],
            status: 'active',
        },
        locationEditData: {},
    }),

    computed: {
        companyId() {
            return this.$route.params.companyId
        },
        locationType() {
            return this.$route.params.accountType
        }
    },

    methods: {
        ...mapMutations({
            setChartAccount: 'chartAccount/setChartAccount'
        }),

        sortChart(data) {
            // (data)
            data.map(i => {
                i.child.sort((a, b) => {
                    if (a.type == 'group' && b.type == 'endpoint') return 1
                    if (a.type == 'endpoint' && b.type == 'group') return -1
                    if (a.type == b.type) return 0
                })
                this.sortChart(i.child)
            })
        },

        openGroupAddModal() {
            this.groupAddData.location_type = this.locationType
            this.$refs.group.toggleModal()
        },

        openLocationAddModal() {
            this.locationAddData.location_type = this.locationType
            this.$refs.location.toggleModal()
        },

        onClickTab() {
            this.getLocationList(this.accountType)
            this.getGroup()
        },

        setGroupArr(data) {
            data.map(item => {
                if (item.type == 'group') {
                    this.groups.push({ id: item.id, name: item.name })
                }
                this.setGroupArr(item.child)
            })
        },

        async onUpdateGroup() {
            // return;
            try {
                this.chartCreateLoading = true
                let res = await this.updateAccountGroup({
                    data: this.editData,
                    id: this.currentEditItemId
                })
                if (!res.status) {
                    this.showError(res.message)
                }
                if (res.status) {
                    this.showSuccess(res.message)
                    this.$refs.editChartAccount.toggleModal();
                    this.getChartAccount(this.$route.params.accountType)
                }
            } catch (err) {
                if (!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if (err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.chartCreateLoading = false
            }
        },

        async getHomeContent(type) {
            try {
                this.chartLoading = true
                let res = await this.fetchHome()
                if (!res.status) {
                    this.showError(res.message)
                }
                if (res.status) {
                    this.homeContent = res.data
                }
            } catch (err) {
                if (!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if (err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.chartLoading = false
            }
        },

        async getClue() {
            try {
                this.loading = true
                let res = await this.fetchClue(this.companyId)
                if (!res.status) {
                    this.showError(res.message)
                }
                if (res.status) {
                    this.clue = res.data
                }
            } catch (err) {
                if (!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if (err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        async getLocationList() {
            const query = `?company_id=${this.companyId}&location_type=${this.$route.params.accountType}`
            try {
                this.loading = true
                let res = await this.fetchLocationList(query)
                if (!res.status) {
                    this.locationList = []
                }
                if (res.status) {
                    this.locationList = res.data
                }
            } catch (err) {
                if (!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if (err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        async getAccountHeads() {
            try {
                this.loading = true
                let res = await this.fetchAccountHeads(this.companyId)
                if (!res.status) {
                    this.showError(res.message)
                }
                if (res.status) {
                    this.accountHeads = res.data
                }
            } catch (err) {
                if (!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if (err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        async getUnit() {
            try {
                this.loading = true
                let res = await this.fetchUnit(this.companyId)
                if (!res.status) {
                    this.showError(res.message)
                }
                if (res.status) {
                    this.units = res.data
                }
            } catch (err) {
                if (!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if (err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        async getGroup() {
            try {
                this.loading = true
                let res = await this.fetchBusinessLocationGroup(this.companyId, this.locationType)
                if (!res.status) {
                    this.businessLocationGroup = []
                }
                if (res.status) {
                    this.businessLocationGroup = res.data
                }
            } catch (err) {
                if (!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if (err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        async handleGroupUpdate() {
            // return;
            try {
                this.$refs.groupedit.loading = true
                this.groupEditData.company_id = this.companyId
                // have to refactor
                let res = await this.updateBusinessLocationGroup(this.groupEditData)
                if (!res.status) {
                    this.showError(res.message)
                }
                if (res.status) {
                    this.$refs.groupedit.toggleModal()
                    this.getGroup()
                    this.getLocationList(this.accountType)
                    this.showSuccess(res.message)
                }
            } catch (err) {
                console.log(err);
                if (!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if (err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.$refs.groupedit.loading = false
            }
        },
        async handleGroupSubmit() {
            try {
                this.$refs.group.loading = true
                this.groupAddData.company_id = this.companyId
                // have to refactor
                let res = await this.storeBusinessLocationGroup(this.groupAddData)
                if (!res.status) {
                    this.showError(res.message)
                }
                if (res.status) {
                    this.$refs.group.toggleModal()
                    this.getGroup()
                    this.getLocationList(this.accountType)
                    this.fet
                    this.groupAddData = {
                        name: '',
                        location_type: null,
                        status: 'active',
                        company_id: '',
                        head_group_id: null
                    }
                    this.showSuccess(res.message)
                }
            } catch (err) {
                if (!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if (err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.$refs.group.loading = false
            }
        },

        async handleLocationUpdate() {
            // return;
            try {
                this.$refs.locationedit.loading = true
                this.locationEditData.company_id = this.companyId
                // have to refactor
                let res = await this.updateBusinessLocation(this.locationEditData)
                if (!res.status) {
                    this.showError(res.message)
                }
                if (res.status) {
                    this.$refs.locationedit.toggleModal()
                    this.getGroup()
                    this.getLocationList(this.accountType)
                    this.showSuccess(res.message)
                }
            } catch (err) {
                console.log(err);
                if (!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if (err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.$refs.locationedit.loading = false
            }
        },
        async handleLocationSubmit() {
            try {
                this.$refs.location.loading = true
                this.locationAddData.company_id = this.companyId
                // have to refactor
                let res = await this.storeBusinessLocation(this.locationAddData)
                if (!res.status) {
                    this.showError(res.message)
                }
                if (res.status) {
                    this.$refs.location.toggleModal()
                    this.getGroup()
                    this.getLocationList(this.accountType)
                    this.fet
                    this.locationAddData = {
                        name: '',
                        code: '',
                        description: '',
                        location_type: null,
                        status: 'active',
                        company_id: '',
                        head_group_id: null
                    }
                    this.showSuccess(res.message)
                }
            } catch (err) {
                if (!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if (err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.$refs.location.loading = false
            }
        },

        setDefaultHead(data) {
            let product = this.product
            if (!data.length) return
            //set first account payable as default
            let firstPurchaseAccount = data.find(i => i.account_slug == 'purchase_accounts')
            if (firstPurchaseAccount) product.purchase_account_head_id = firstPurchaseAccount.id
            //set first account receivable as default
            let firstReceiveAccount = data.find(i => i.account_slug == 'sales_accounts')
            if (firstReceiveAccount) product.sales_account_head_id = firstReceiveAccount.id
            //set first cost of sales head as default
            let firstCostAccount = data.find(i => i.account_slug == 'cost_of_sales')
            if (firstCostAccount) product.cost_of_sales_account_head_id = firstCostAccount.id
            //set first inventory head as default
            let firstInventoryAccount = data.find(i => i.account_slug == 'inventory_assets')
            if (firstInventoryAccount) product.inventory_account_head_id = firstInventoryAccount.id

        },

        async getInitialData() {
            const query = `?company_id=${this.companyId}&location_type=${this.$route.params.accountType}`
            this.loading = true
            let p1 = this.fetchAccountHeads(this.companyId)
            let p2 = this.fetchBusinessLocationGroup(this.companyId, this.locationType)
            let p3 = this.fetchLocationList(query)
            Promise.all([
                p1.then(res => {
                    if (res.data) {
                        this.accountHeads = res.data
                        this.setDefaultHead(res.data)
                    }
                }),
                p2.then(res => {
                    if (res.data) this.businessLocationGroup = res.data
                }),
                p3.then(res => {
                    if (res.data) this.locationList = res.data
                }),
            ])
                .then(res => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    // console.error(error.message)
                });
        },
    },

    setup() {
        const showError = inject('showError');
        const showSuccess = inject('showSuccess');

        const {
            fetchHome,
            chartCreateLoading,
            updateAccountGroup,
            chartLoading,

            fetchAccountHeads,
            fetchBusinessLocationGroup,
            storeBusinessLocationGroup,
            updateBusinessLocationGroup,
            fetchLocationList,
            storeBusinessLocation,
            updateBusinessLocation,
        } = handleInventory()

        return {
            chartCreateLoading,
            updateAccountGroup,
            chartLoading,
            fetchHome,
            showError,
            showSuccess,

            fetchAccountHeads,
            fetchBusinessLocationGroup,
            fetchLocationList,
            storeBusinessLocationGroup,
            updateBusinessLocationGroup,
            storeBusinessLocation,
            updateBusinessLocation,
        }
    },

    mounted() {
        const groupCreateModal = this.$refs.group
        const groupEditModal = this.$refs.groupedit
        const locationAddModal = this.$refs.location
        const locationEditModal = this.$refs.locationedit

        this.getInitialData()
        this.getHomeContent() //status, voucher, account_type

        this.emitter.on('onClickAddGroup', (evt) => {
            this.groupAddData.location_type = this.locationType
            this.groupAddData.head_group_id = evt.id
            groupCreateModal.toggleModal();
        })

        this.emitter.on('onGroupUpdate', (evt) => {
            let data = {
                id: evt.data.id,
                name: evt.data.name,
                location_type: evt.data.location_type,
                status: 'active',
                company_id: this.companyId,
                head_group_id: evt.data.business_location_id,
                _method: 'PATCH'
            }
            this.groupEditData = data
            this.showParent = true
            groupEditModal.toggleModal();
        })

        this.emitter.on('onClickAddLocation', (evt) => {
            this.locationAddData.location_type = this.locationType
            this.locationAddData.head_group_id = evt.id
            locationAddModal.toggleModal();
        })

        this.emitter.on('onLocationUpdate', (evt) => {
            let data = {
                id: evt.data.id,
                name: evt.data.name,
                code: evt.data.code,
                description: evt.data.description,
                location_type: evt.data.location_type,
                status: 'active',
                company_id: this.companyId,
                head_group_id: evt.data.business_location_id,
            }
            this.locationEditData = data
            this.showParent = true
            locationEditModal.toggleModal();
        })
    }
}
</script>


<style scoped>
._campaign_title{
    display: flex;
    align-items: flex-end;
}

</style>